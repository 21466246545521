import type { ItemModel } from 'o365.modules.DataObject.Types.ts';
import type DataObject from 'o365.modules.DataObject.ts';
import logger from 'o365.modules.Logger.ts';
import localStorage from 'o365.modules.StorageHelpers.ts';

export default class AutocompleteControl {
    private _props: AutocompleteProps;
    private _keydownDebouncer = new Debouncer(250);
    /** Loaded data array */
    private _data: ItemModel[] = [];
    /**
     * Visible data array. Since not using virtual scroll here do to absolute position limitations, 
     * need another way to limit shown data. Limiting results to 25 with a button to show more (add 25 more)
     */
    private _shownData: ItemModel[] = [];
    /** Internal count of pages to show */
    private _currentPage = 1;

    private _isLoaded = false;
    private _isLoading = false;
    private _isSelected = false;

    private _updated = new Date();
    private _updateModelValue: (pValue: any) => void;

    private _dropdown: any;
    private _navigation?: AutocompleteNavigationControl;

    private get _dataObject() { return this._props.dataObject; }

    private get _localStorageKey() {
        if (this.props.id) {
            return `${this.props.id}_autocomplete_recents`
        } else {
            return undefined;
        }
    }
    private _searchValue?: string;
    private _showingRecents = false;

    /** Reactive props from autocomplete component */
    get props() { return this._props; }
    /** Data shown in the autocomplete dropdown */
    get data() { return this._shownData; }
    get loadedDataLength() { return this._data.length; }
    /** Field used when  */
    /**
     * Field used to filter in the given dataobject 
     * and setting modelValue from selection 
     */
    get field() { return this._props.field; }
    get updated() { return this._updated; }
    get isLoaded() { return this._isLoaded; }
    get isLoading() { return this._isLoading; }
    get isSelected() { return this._isSelected; }
    /** Indicates that the shown suggestions are from recents  */
    get showingRecents() { return this._showingRecents; }
    /** Indicates that more data can be shown/loaded */
    get canShowMore() {
        if (this._showingRecents || this._isLoading) { return false; }
        else if (this._dataObject) {
            return this._dataObject.rowCount == null || this.data.length < this._dataObject.rowCount;
        } else {
            return this.data.length < this._data.length;
        }
    }

    /** Current input value */
    get searchValue() { return this._searchValue; }
    set searchValue(pValue) { this._searchValue = pValue; }

    /** Helper navigation control */
    get navigation() { return this._navigation; }

    constructor(pProps: AutocompleteProps, pOptions: {
        updateModelValue: (pSelection: ItemModel | null) => void
    }) {
        this._props = pProps;
        this._updateModelValue = pOptions.updateModelValue;
    }

    /** Search for autocomplete values with a debounce */
    searchWithDebounce(_pEvent: InputEvent) {
        this._navigation?.clearState();
        this._keydownDebouncer.run(() => {
            this.search();
        });
    }

    /** Clear the autocomplete search debounce */
    clearSearchDebounce() {
        this._keydownDebouncer.clear();
    }

    /** Get autocomplete data for current searchValue */
    async search(pOptions?: {
        /** Instead of clearing search results send request for '' value */
        searchForEmpty?: boolean
    }) {
        try {
            if (this._dropdown && !this._dropdown.isOpen) {
                this._dropdown.open();
            }
            this._currentPage = 1;
            this._isSelected = false;
            this._showingRecents = false;
            this._isLoading = true;
            let isValidLoad = true;
            if (this._props.getData) {
                if (this.searchValue || pOptions?.searchForEmpty) {
                    const result = await this._props.getData(this.searchValue ?? '');
                    this._data.splice(0, this._data.length, ...result);
                } else {
                    this.clearData();
                    isValidLoad = false;
                }
            } else if (this._dataObject) {
                this._checkDataObjectConfiguration();
                const filterItem = this._dataObject.filterObject.getItem(this.field);
                if (filterItem == null) { logger.warn(`Autocomplete: Could not get filter item for ${this.field}`); return; }
                if (this.searchValue) {
                    filterItem.selectedValue = this.searchValue;
                    filterItem.operator = this.props.filterOperator ?? 'beginswith';
                    await this._dataObject.filterObject.apply();
                    this.clearData();
                    this._data.splice(0, 0, ...this._dataObject.data);
                } else {
                    await this._dataObject.filterObject.clear();
                    this.clearData();
                    isValidLoad = false;
                }
            } else {
                this.clearData();
                isValidLoad = false;
            }
            this._isLoading = false;
            this._isLoaded = isValidLoad;
            this.update();
            if (!this._props.disableFirstItemAutoSelect) {
                this.navigation?.setIndex(this._data.length > 0 ? 0 : null);
            } else {
                this.navigation?.clearState();
            }
        } catch (ex) {
            this._isLoading = false;
            this._isLoaded = false;
            logger.error(ex);
        }
    }

    /** Clear internal data arrays */
    clearData() {
        this._data.splice(0, this._data.length);
        this._shownData.splice(0, this._shownData.length);
    }

    /** Update rendered data */
    update() {
        this._updated = new Date();
        if (this._dataObject) {
            this._shownData.splice(0, this._shownData.length, ...this._data);
        } else {
            this._shownData.splice(0, this._shownData.length, ...this._data.slice(0, this._currentPage * 25));
        }
    }

    /** Called when autocompelte is mounted, initialize navigation */
    initialize(pOptions: {
        dropdown: any
    }) {
        this._dropdown = pOptions.dropdown;
        this._navigation = new AutocompleteNavigationControl({
            close: () => {
                this._dropdown?.close();
            },
            getScrollContainer: () => {
                return this._dropdown.container?.querySelector('.autocomplete-scroll-container');
            },
            isValidIndex: (pIndex: number) => {
                if (this.data[pIndex] != null) {
                    return true;
                } else if (this.canShowMore) {
                    return pIndex === this.data.length;
                } else {
                    return false;
                }
            },
            updateValue: (pIndex: number) => {
                if (this.data[pIndex] != null) {
                    this.select(this._data[pIndex]);
                } else if (this.canShowMore && pIndex === this.data.length) {
                    this.loadMore();
                }
            },
            getItemSize: () => this._props.itemSize
        });
    }

    /**
     * Select value
     * Calls bind and update:modelValue
     */
    select(pValue?: ItemModel) {
        if (pValue) {
            this.storeRecent(pValue);
        }
        if (pValue == null) {
            pValue = {};
        }
        if (this.props.bind) {
            this.props.bind(pValue);
        }

        const value = pValue[this.field];
        this._updateModelValue(value);

        this._searchValue = value;

         // Activates the focus handler which in turn opens the dropdown again, need a way to disable that handler here
        // this.focusInput();
        this._isSelected = true;
        this._dropdown?.close();
    }

    /** On dropdown open */
    onOpen() {
        this._navigation?.addHandler(this._dropdown.target);
    }

    /** On dropdown close */
    onClose() {
        this._navigation?.removeHandler();
        this._isLoaded = false;
    }

    onFocus() {
        this._currentPage = 1;
        if (this.searchValue) {
            this._isSelected = true;
        }
        if (this.props.searchOnFocus && this.props.getData) {
            this.search({
                searchForEmpty: true
            });
        } else {
            this.loadRecents();
        }
    }

    /** Focus the input using the dropdown component */
    focusInput() {
        if (this._dropdown?.target?.focus) {
            this._dropdown.target.focus();
        }
    }

    /**
     * Show more
     * If dataObject is used then next page will be loaded, 
     * otherwise will append 25 rows to the shown data
     */
    async loadMore() {
        if (this._isLoading) { return; }
        this._isLoading = true;
        if (this._dataObject) {
            await this._dataObject.dynamicLoading.loadNextPage();
            this._data.splice(0, this._data.length, ...this._dataObject.data);
        } else {
            this._currentPage += 1;
        }
        this._isLoading = false;
        this.update();
        this.navigation?.scrollToCurrent();
    }

    /** Get current selection */
    getCurrent() {
        if (this._navigation) {
            const index = this._navigation.currentIndex;
            return index == null ? undefined : this.data[index];
        }
        return undefined;
    }

    /** Load recent selections from local storage and show them */
    loadRecents() {
        if (this.searchValue) { return; }
        const recents = this._getRecents().map(x => ({ ...x, _recent: true }));
        if (recents.length > 0) {
            this._showingRecents = true;
            this._data.splice(0, this._data.length, ...recents);
            this._isLoaded = true;
            if (this._dropdown?.open) {
                this._dropdown.open();
            }
            this.update();
        }
    }

    /**
     * Store selection as recent. If it was already exists then
     * push it to the top
     */
    storeRecent(pSel: ItemModel) {
        if (this._localStorageKey == null) { return; }
        const recents = this._getRecents();
        let item = this._dataObject ? pSel.item ?? pSel : pSel;
        if (item == null) { return; }
        item = JSON.parse(JSON.stringify(item));
        delete item._recent;
        const existingIndex = recents.findIndex(x => JSON.stringify(x) === JSON.stringify(item));
        if (existingIndex !== -1) {
            recents.splice(existingIndex, 1);
        } else if (recents.length > 4) {
            recents.pop();
        }
        recents.unshift(item);
        this._storeRecents(recents);
    }

    /**
     * Remove selections from recents by index.
     * Accepted values: [0,4]
     */
    removeRecent(pIndex: number) {
        if (this._localStorageKey == null || pIndex == null) { return; }
        const recents = this._getRecents();
        recents.splice(pIndex, 1);
        this._storeRecents(recents);
        this._data.splice(0, this._data.length, ...recents);
        if (this._data.length === 0) { this._isLoaded = false; }
        this.focusInput();
        this.update();
    }

    /** Get recent selections from local storage */
    private _getRecents() {
        if (this._localStorageKey == null) { return []; }

        try {
            const localItem = localStorage.getItem(this._localStorageKey) || '[]';

            const recentSelections: ItemModel[] = JSON.parse(localItem);
            return recentSelections;
        } catch (ex) {
            logger.error(ex);
            return [];
        }
    }

    /** Store array of selections as recent */
    private _storeRecents(pRecents: ItemModel[]) {
        if (this._localStorageKey == null) { return; }
        try {
            if (pRecents.length > 0) {
                localStorage.setItem(this._localStorageKey, JSON.stringify(pRecents));
            } else {
                localStorage.removeItem(this._localStorageKey);
            }
        } catch (ex) {
            logger.error(ex);
        }
    }

    /**
     * Check if dataobject is configured to use dynamic loading. 
     * If no, then set it up
     */
    private _checkDataObjectConfiguration() {
        if (this._dataObject == null) { return; }
        if (this._dataObject.recordSource.maxRecords === -1) {
            this._dataObject.recordSource.maxRecords = 25;
        }
        if (!this._dataObject.hasDynamicLoading || !this._dataObject.dynamicLoading.enabled) {
            this._dataObject.enableDynamicLoading();
        }
    }

}


/** Helper class for implementing navigation on the autocomplete */
class AutocompleteNavigationControl {
    /** Current focused index */
    currentIndex: number | null = null;
    /** Indicates that last keydown was for navigation, next enter will select the current index */
    lastKeyIsNavigation = false;
    private _input?: HTMLElement;
    private _boundKeyDown?: typeof this._handleKeyDown;
    private _apiObject: {
        close: () => void;
        isValidIndex: (pIndex: number) => boolean;
        updateValue: (pIndex: number) => void;
        getScrollContainer: () => HTMLElement | undefined;
        getItemSize: () => number;
    };

    constructor(pOptions: {
        close: () => void;
        isValidIndex: (pIndex: number) => boolean;
        updateValue: (pIndex: number) => void;
        getScrollContainer: () => HTMLElement | undefined;
        getItemSize: () => number;
    }) {
        this._apiObject = pOptions;
    }

    /** Set index as focused */
    setIndex(pIndex: number | null) {
        this.currentIndex = pIndex;
        this.lastKeyIsNavigation = true;
    }

    /** Unset focused index */
    clearState() {
        this.currentIndex = null;
        this.lastKeyIsNavigation = false;
    }

    /** Add navigation events on an input */
    addHandler(inputEl: HTMLElement, currentIndex?: number) {
        this._input = inputEl;
        this._boundKeyDown = this._handleKeyDown.bind(this);
        this.clearState();
        if (currentIndex != null) {
            this.currentIndex = currentIndex;
        }
        this._input.addEventListener('keydown', this._boundKeyDown);
    }

    /** Remove navigation events */
    removeHandler() {
        this._input?.removeEventListener('keydown', this._boundKeyDown!);
    }

    private _handleKeyDown(e: KeyboardEvent) {
        switch (e.key) {
            case 'Escape':
                this._apiObject.close();
                break;
            case 'ArrowDown':
                this.lastKeyIsNavigation = true;
                this._moveDown();
                e.preventDefault();
                e.stopPropagation();
                break;
            case 'ArrowUp':
                this.lastKeyIsNavigation = true;
                this._moveUp();
                e.preventDefault();
                e.stopPropagation();
                break;
            case 'Enter':
                this._selectCurrentRow(e);
                break;
            default:
                this.lastKeyIsNavigation = false;
                break;
        }
    }

    private _moveDown() {
        const moved = this._changeIndex(false);
        if (moved) {
            this.scrollToCurrent();
        }

    }

    private _moveUp() {
        const moved = this._changeIndex(true);
        if (moved) {
            this.scrollToCurrent();
        }
    }

    private _changeIndex(decrement = false) {
        if (this.currentIndex == null) {
            if (this._rowExists(0)) {
                this.currentIndex = 0;
                return true;
            } else {
                return false;
            }
        } else {
            const newIndex = decrement ? this.currentIndex - 1 : this.currentIndex + 1;
            if (this._rowExists(newIndex)) {
                this.currentIndex = newIndex;
                return true;
            } else {
                return false;
            }
        }
    }

    private _rowExists(pIndex: number | null) {
        if (pIndex == null) {
            return false;
        } else {
            return this._apiObject.isValidIndex(pIndex);
        }
    }

    private _selectCurrentRow(e: KeyboardEvent) {
        if (!this.lastKeyIsNavigation || !this._rowExists(this.currentIndex)) { return; }
        e.preventDefault();
        e.stopPropagation();
        this._apiObject.updateValue(this.currentIndex!);
    }

    /** Scroll the list to the current focused index */
    scrollToCurrent() {
        const scrollContainer = this._apiObject.getScrollContainer();
        if (this.currentIndex == null || scrollContainer == null) { return; }
        const buffer = scrollContainer.clientHeight / 2;

        let newScroll = this.currentIndex * 24;
        const topScroll = scrollContainer.scrollTop;
        const bottomScroll = topScroll + scrollContainer.clientHeight;

        if (newScroll <= bottomScroll + buffer) {
            newScroll -= buffer;
        } else if (topScroll <= newScroll) {
            newScroll += buffer;
        }
        window.requestAnimationFrame(() => {
            scrollContainer.scrollTop = newScroll
        });
    }
}

class Debouncer {
    private _debounceTime: number;
    private _debounce: number | null = null;
    constructor(pDebounce = 500) {
        this._debounceTime = pDebounce;
    }

    clear() {
        if (this._debounce) { window.clearTimeout(this._debounce); }
        this._debounce = null;
    }

    run(pFunction: () => void) {
        this.clear();
        this._debounce = window.setTimeout(pFunction, this._debounceTime);
    }
}

export type AutocompleteProps = {
    id?: string,
    modelValue?: any,
    dataObject?: DataObject,
    getData?: (pValue: string) => Promise<ItemModel[]>,
    disableFirstItemAutoSelect?: boolean,
    bind?: (pSel: ItemModel) => void,
    field: string,
    itemSize: number,
    minWidth: number | string,
    value?: any,
    itemTitle?: (pRow: ItemModel) => string,
    wrapperClass?: any,
    filterOperator?: string,
    hideNoResults?: boolean,
    searchOnFocus?: boolean
};
